import kayla_img_1 from "../../images/Blind-angels-kayla-profile.png";
import kayla_img_1_mobile from "../../images/Blind-angels-kayla-profile_mobile.png";

import kayla_img_2 from "../../images/Blind-angels-kayla-video.png";
import kayla_img_2_mobile from "../../images/Blind-angels-kayla-video.png";

const fullName = "Kayla Rena Gore";

const copyListKayla = [
  {
    type: "text",
    content:
      "Kayla Rena Gore is an advocate and co-founder of My Sistah’s House, a grassroots organization that provides emergency housing and other services to the LGBTQIA+ community in Memphis, Tennessee.<sup>1,2</sup> For Kayla, providing access to stable housing for transgender people is how she takes care of her community. She started My Sistah’s House in 2016 to help ensure that transgender women and other LGBTQIA+ individuals of color had a place to live, as she found many shelters in Memphis were unwelcoming toward transgender people or actively discriminated against them.<sup>1</sup>",
  },
  {
    type: "image",
    content: {
      src: kayla_img_1,
      mobileSrc: kayla_img_1_mobile,
      alt: "A woman, Kayla, sitting down and looking at the camera",
      caption: "Kayla Rena Gore",
    },
  },
  {
    type: "text",
    content:
      "Homelessness is an important issue for transgender people; nearly 1 in 3 transgender people in the US surveyed in 2015 reported having experienced homelessness at some point in life.<sup>3</sup> For Black transgender women, housing instability is even more pronounced. More than half of Black American transgender women surveyed in 2015 reported having experienced homelessness at some point in life. A reported 20% of Black transgender women in the US survey had been kicked out of the house because of their gender identity. In addition, about 49% of Black transgender women reported having experienced some form of housing discrimination in the previous year, such as being denied a potential home or apartment or being evicted from their current home or apartment.<sup>4</sup>",
  },
  {
    type: "blockquote",
    content:
      "“I've been in situations where I had my housing taken away from me because people didn't understand the way that I live life.... But me, being a Black trans woman, I can relate to the people who are in our housing.”",
    author: fullName,
  },
  {
    type: "text",
    content:
      "Traditional shelters have often not met the needs of transgender people. Some shelters, for example, inappropriately house transgender people based on sex assigned at birth rather than gender identity.<sup>5</sup> Among those surveyed in 2015 who had experienced homelessness and stayed in a shelter in the US in the previous year, 70% reported some form of mistreatment, including being harassed, assaulted, or kicked out because of their gender identity. As may be expected, many of the respondents who experienced homelessness said they avoided shelters for fear of harassment or discrimination.<sup>3</sup>",
  },
  {
    type: "text",
    content:
      "Stigma, discrimination, and homelessness can put transgender people at higher risk for HIV.<sup>6</sup> According to the Centers for Disease Control and Prevention (CDC), 6 in 10 Black transgender women in major cities in the United States are living with HIV.<sup>7</sup> At the same time, a lack of stable housing has been identified as a significant barrier to HIV medical care, adherence to treatment, and sustained viral suppression.<sup>8,</sup>*",
  },
  {
    type: "blockquote",
    content:
      "“A lot of times when I was homeless, going to the doctor was the last thing on my mind. The first thing on my mind was making sure that my property was safe, and [I] was safe.”",
    author: fullName,
  },

  {
    type: "text",
    content:
      "Providing stable housing has been identified as an important component of interventions for people living with HIV. Stable housing can help them to better adhere to their HIV medication and access medical care and supportive services when needed.<sup>9</sup> As such, the <em>National HIV/AIDS Strategy for the United States 2022–2025</em> identifies access to stable housing as a key component in its plan to end the HIV epidemic. The Strategy suggests developing or scaling up interventions that  address the overlapping issues of HIV, housing instability, mental health, violence, substance abuse, and gender, particularly among marginalized groups like transgender women.<sup>10</sup>",
  },
  {
    type: "text",
    content:
      "Additionally, federal programs are working to address housing instability and reduce discrimination against transgender people. The US Department of Housing and Urban Development (HUD) Office of HIV/AIDS Housing, which manages the Housing Opportunities for People With AIDS (HOPWA) program, provides grants to local communities, states, and nonprofit organizations that, in turn, extend these housing services to people living with HIV and their families.<sup>9</sup> The Biden Administration has taken steps to address discrimination against transgender people in federally sponsored shelters, housing assistance programs, and other accommodations. In 2021, HUD reconfirmed its commitment to the Equal Access Rule, originally enacted to ensure no one was denied housing or housing assistance based on their sexual orientation or gender identity.<sup>11</sup>",
  },
  {
    type: "text",
    content:
      "Organizations like My Sistah’s House also play an important role in providing housing to vulnerable communities. In addition to running emergency shelters and offering multipronged resource assistance and advocacy, Kayla has launched the Tiny House Project designed to offer permanent housing to those in need.<sup>1</sup> The organization has purchased land and is working with a team of architects offering their services pro bono to build 20 tiny homes for transgender and queer women of color in Memphis.<sup>12</sup> ",
  },
  {
    type: "blockquote",
    content:
      "“[Tiny house recipients] are like, ‘I created this masterpiece…. It's not something that someone else [created] for me. But this is something that I created to make me feel at home.’”",
    author: fullName,
  },
  {
    type: "text",
    content:
      "The Tiny House Project is unique because recipients get to be involved in the design of the structures from the start, so they can feel a sense of ownership and home as soon as they walk through the door. So far, the organization has built six tiny homes for transgender women in need and construction is ongoing.<sup>12</sup>",
  },
  {
    type: "image",
    content: {
      src: kayla_img_2,
      mobileSrc: kayla_img_2_mobile,
      alt: "Kayla and man stand inside of a home that is being built",
      caption: "",
    },
  },
];

export default copyListKayla;
